<template>
    <div id="wrapper" class="bg-white pt-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-8 offset-xl-2 col-lg-8 col-md-8 offset-lg-2 offset-md-2">
                    <div class="d-flex">
                        <div class="text-left p-4 pb-2">
                            <h3 class="text-uppercase text-orange fw-bold mb-1 mt-2" style="letter-spacing: 1px">Reset Password</h3>                           
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <status v-model:status_data=status_data></status>
                            </div>
                        </div>
                    </div>

                    <form id="form" action="#" @submit.prevent="handleSubmit()">
                        <div class="border-bottom-0 p-4 pt-2">
                            <div class="row mt-4" id="password-row">
                                <div class="input-group">

                                    <input id="password" type="password" class="form-control" v-model="form_data.password" placeholder="Create new Password" required>
                                    
                                    <span class="input-group-text pe-4" @click="seePassword('password')">
                                        <font-awesome-icon id="eye" :icon="['fa', 'eye']" />
                                    </span>
                                </div>
                            </div>

                            <div class="row mt-4" id="password_confirmation-row">
                                <div class="input-group">

                                    <input id="password_confirmation" type="password" class="form-control" v-model="form_data.password_confirmation" placeholder="Confirm new Password" required>
                                    
                                    <span class="input-group-text pe-4" @click="seePassword('password_confirmation')">
                                        <font-awesome-icon id="eye" :icon="['fa', 'eye']" />
                                    </span>
                                </div>
                            </div>

                            <div class="row" id="login">
                                <div class="col">
                                    <button class="btn bg-orange text-white btn-sm mt-4 w-100 text-uppercase fw-bold pt-2 pb-2" type="submit">
                                        <span style="letter-spacing: 2px">Update Password</span> <font-awesome-icon class="icon ms-1 me-1" :icon="['fa', 'unlock']" />
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-1">
                                <small class="text-muted text-start">Go back to <router-link class="text-orange fw-bold text-decoration-none" :to="'/login'">Login</router-link></small>
                            </div>
                        </div>
                    </form>
                </div>  
            </div>
        </div>

        <div class="container-fluid">
            <p class="text-center pt-4">Don't have an account? <router-link :to="'/register'" class="text-orange text-decoration-none">Create One</router-link></p>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '../../assets/scss/base/_base.scss';
    @import '../../assets/scss/base/_fonts.scss';
    @import '../../assets/scss/helpers/breakpoints.scss';

    .input-group {
        .input-group-text {
            background: #eee;
        }

        .form-control {
            background: #eee;
        }
    } 
    
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'Login',
        props: {
            email: {
                required: true,
                type: String
            },
            token: {
                required: true,
                type: String
            }
        },
        components: {
            Status,
        },
        data() {
            return {
                form_data: {
                    password: null, 
                    password_confirmation: null,
                    token: this.token,
                    email: this.email
                },
                status_data: {
                    "success": false,
                    "success_message": null,
                    "error": false,
                    "error_message": null
                },
                loading: false
            }
        },
        methods: {
            handleSubmit() {
                if(this.form_data.password == this.form_data.password_confirmation) {
                    if(this.form_data.password.length > 7) {
                        this.axios.post('api/reset-password', this.form_data).then(() => {
                            this.status_data.loading = false
                            this.status_data.success = true
                            this.status_data.error = false
                            this.status_data.success_message = 'Password reset!'

                            setTimeout(() => {
                                this.status_data.success = false,
                                this.$router.push({name: 'Login'});
                            }, 2000);

                        }).catch((error) => {
                            this.status_data.loading = false
                            this.status_data.success = false
                            this.status_data.error = true
                            this.status_data.error_message = error.message
                            console.log(error);
                        }) 
                    } else {
                        this.status_data.loading = false
                        this.status_data.success = false
                        this.status_data.error = true
                        this.status_data.error_message = 'Your password length is under 8 digits. Please choose a longer password.'
                    }  
                } else {
                    this.status_data.loading = false
                    this.status_data.success = false
                    this.status_data.error = true
                    this.status_data.error_message = 'Your passwords don\'t match. Please verify them and try again.'
                }               
            },
            hideAlert() {
                if(this.success == true) {
                    this.success = false
                } else if(this.error == true) {
                    this.error = false
                }
            },
            seePassword(id) {
                if(document.querySelector('#' + id).type == 'password') {
                    document.querySelector('#' + id).type = 'text'
                } else {
                    document.querySelector('#' + id).type = 'password'
                }
            },
        }
    }
</script>